@keyframes pulseAlert {
  0% {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.8);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 0, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.8);
  }
}

.alert-box {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  animation: pulseAlert 1.5s infinite;
}
