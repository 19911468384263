body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.cadastrodeprodutos_search {
  height: 290px;
  overflow-y: scroll;
  /* or auto */
}

.agrupador_search {
  height: 300px;
  overflow-y: scroll;
  /* or auto */
}

.flip {
  animation: 0.5s linear flipping;
}

.letra {
  border: solid 4px rgb(26, 26, 26);
  background-color: black;
  min-width: 1px;
  height: 25px;
  /*float:left;*/
  font-weight: 300;
  /*font-size: 30px;*/

  font-family: Arial, Helvetica, sans-serif;
}

@keyframes flipping {
  0% {
    transform: rotateX(0deg);
  }

  50% {
    transform: rotateX(90deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

@keyframes btn-pisca {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.btn-pisca {
  /* background-color: #ce300c;
  color: #fff;
  padding: 0.6em 0.8em;
  display: inline-block;
  text-decoration: none;
  border-radius: 4px;*/
  -webkit-animation: btn-pisca 0.3s linear infinite;
  -moz-animation: btn-pisca 0.3s linear infinite;
  -ms-animation: btn-pisca 0.3s linear infinite;
  -o-animation: btn-pisca 0.3s linear infinite;
  animation: btn-pisca 0.3s linear infinite;
}

@media print {
  .ui.form .field {
    white-space: pre-wrap; /* Respeitar quebras de linha */
    overflow-wrap: break-word; /* Quebrar palavras longas */
  }
}
