/* Esconder a tabela na tela e mostrar apenas na impressão */
.audit-container {
  display: none;
}

@media print {
  /* Exibir a tabela apenas na impressão */
  .audit-container {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 10px;
    margin: 0;
    box-shadow: none;
    border-radius: 0;
  }

  /* Ajuste do cabeçalho */
  .audit-container h2 {
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
  }

  /* Estilização da tabela */
  .audit-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px; /* Reduzir tamanho da fonte */
  }

  .audit-table th,
  .audit-table td {
    padding: 5px; /* Reduzindo o espaçamento interno */
    text-align: center;
    border: 1px solid #000;
  }

  /* Reduzindo a altura das linhas */
  .audit-table tr {
    height: 20px;
  }

  /* Removendo cores de fundo para economizar tinta */
  .audit-table thead {
    background: none;
    color: black;
    font-weight: bold;
  }

  /* Garantir que a impressão ocupe apenas uma folha */
  @page {
    size: A4 portrait; /* Define tamanho A4 vertical */
    margin: 10mm;
  }

  /* Evitar quebras de página no meio da tabela */
  .audit-table {
    page-break-inside: avoid;
  }
}
